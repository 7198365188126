import styled from 'styled-components';

export const ImgWrapper = styled.div`
  max-width: 100%;

  @media ${props => props.theme.media.tablet} {
    max-width: 352px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    min-height: 116px;
  }
`;

export const Img = styled.img`
  max-width: 100%;
`;
