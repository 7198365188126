import styled from 'styled-components';
import { path } from 'ramda';
import { Warning } from '@styled-icons/ionicons-solid/Warning';

export const Wrapper = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;

  @media ${path(['theme', 'media', 'tablet'])} {
    justify-content: center;
    text-align: left;
  }
`;
export const IconWrapper = styled.div`
  width: 50px;
  margin-right: 10px;
`;

export const Header = styled.div`
  font-weight: ${path(['theme', 'fontWeight', 'medium'])};
  font-size: 12px;
  line-height: 16px;
`;

export const Text = styled.div`
  color: ${path(['theme', 'palette', 'jumbo'])};
  font-style: normal;
  font-weight: ${path(['theme', 'fontWeight', 'light'])};
  font-size: 10px;
  line-height: 14px;
`;

export const WarningIcon = styled(Warning)``;
