import React, { useMemo, useCallback } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import {
  DesktopConfiguration,
  MobileConfiguration,
  ConfigurationPageFormValues,
} from '@nn-virtual-pen/education/features/configuration-page';
import {
  DEFAULT_CATEGORY,
  DEFAULT_STEP,
  DEFAULT_STEPS,
  UserConfiguration,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';
import {
  getProductImageSrcMap,
  RoutePath,
  transformCountryInformation,
} from '@nn-virtual-pen/education/utils';
import { GenericLayout } from '../layouts';
import { Helmet } from 'react-helmet';
import { cond, prop, T, pipe, path, find, propEq } from 'ramda';
import { withPrivate } from '../hoc';
import { Initialization } from '../components/initialization';

export const Configure = ({ user }) => {
  const {
    configuration,
    setSteps,
    setStepAndCategory,
    setConfiguration,
  } = user;

  const data = useStaticQuery(graphql`
    query homeInformation {
      information: allCountryInformationJson {
        nodes {
          Country
          Pen {
            Device
            Product
          }
          PhoneSupport
          EmailSupport
          PromoCode {
            Product
            Code
          }
        }
      }
      productImages: allFile(
        filter: {
          relativePath: { regex: "/^products/" }
          extension: { eq: "png" }
        }
      ) {
        nodes {
          publicURL
          relativePath
          name
          childImageSharp {
            fluid(
              maxWidth: 600
              fit: CONTAIN
              webpQuality: 10
              background: "rgba(0,0,0,0)"
            ) {
              src
              srcSetWebp
              srcWebp
              originalName
            }
          }
        }
      }
    }
  `);

  const { t } = useTranslate();

  const { fieldOptions, productDevice } = useMemo(
    () => transformCountryInformation(data.information.nodes, t),
    [data.information.nodes, t]
  );

  const productImages = useMemo(
    () => getProductImageSrcMap(data.productImages.nodes),
    [data.productImages.nodes]
  );

  const callback = useCallback(
    (values: ConfigurationPageFormValues) => {
      const country = values?.country || configuration.country;
      const product = values?.product || '' as any;
      const { PhoneSupport, EmailSupport, PromoCode } = pipe(
        path(['information', 'nodes']),
        find(propEq('Country', country))
      )(data) as any

      const newConfiguration: UserConfiguration = {
        country,
        product,
        device: productDevice?.get(country)?.get(product) || '',
        phoneSupport: PhoneSupport,
        emailSupport: EmailSupport,
        promoCodes: PromoCode,
      };

      setSteps(DEFAULT_STEPS);
      setStepAndCategory(DEFAULT_STEP, DEFAULT_CATEGORY);

      setConfiguration(newConfiguration);

      const isSettingsStepCompleted =
        !!newConfiguration.country && !!newConfiguration.product;

      if (isSettingsStepCompleted) {
        return navigate(RoutePath.Home);
      }
    },
    [
      configuration,
      setConfiguration,
      setSteps,
      setStepAndCategory,
      productDevice,
    ]
  );

  return (
    <GenericLayout withMedia>
      {({ mobile, tablet, desktop }) => (
        <>
          <Helmet title="Configure" />
          {mobile && (
            <MobileConfiguration
              productImages={productImages}
              configuration={configuration}
              callback={callback}
              fieldOptions={fieldOptions}
            />
          )}
          {(tablet || desktop) && (
            <DesktopConfiguration
              productImages={productImages}
              configuration={configuration}
              callback={callback}
              fieldOptions={fieldOptions}
            />
          )}
        </>
      )}
    </GenericLayout>
  );
};

export default withPrivate(
  cond([
    [prop('isLoading'), Initialization],
    [T, Configure],
  ])
);
