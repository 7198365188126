import React from 'react';
import {
  BlurredCircle as StyledBlurredCircle,
  BlurredCirclePosition,
  Label,
  Title,
} from '@nn-virtual-pen/education/ui';
import { DropDownField } from '@nn-virtual-pen/ui';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import {
  useConfiguration,
  useProductOptions,
} from '../education-features-configuration-page.hook';
import * as Styled from './desktop-configuration.styled';
import { ProductViewer } from '../product-viewer';
import { useDidUpdateEffect } from '@nn-virtual-pen/education/utils';
import {
  TranslationKey,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';

export const DesktopConfiguration = ({
  productImages,
  configuration,
  fieldOptions,
  callback,
}) => {
  const { t } = useTranslate();
  const { setValue, watch, onSubmit, control, formState } = useConfiguration(
    {
      includeCountry: true,
      includeProduct: true,
      includeTermsAndConditions: false,
    },
    configuration,
    callback
  );

  const { errors, isSubmitting } = formState;

  const { country, product } = watch(['country', 'product']);

  const productOptions = useProductOptions(country, fieldOptions);

  useDidUpdateEffect(() => {
    setValue('product', '', {});
  }, [country, setValue]);

  return (
    <Styled.Wrapper onSubmit={onSubmit}>
      <StyledBlurredCircle position={BlurredCirclePosition.left} />
      <Styled.Element>
        <Styled.TitleWrapper>
          <Title>{t(TranslationKey.page1_intro_text)}</Title>
        </Styled.TitleWrapper>
        <Styled.Info>{t(TranslationKey.page1_header_2)}</Styled.Info>
      </Styled.Element>
      <Styled.FormElementWrapper>
        <Styled.DropDownWrapper>
          <Label align="left">{t(TranslationKey.page1_select_country)}</Label>
          <DropDownField
            fullWidth
            control={control}
            errors={errors}
            name="country"
            placeholder={t(TranslationKey.page1_country)}
            options={fieldOptions.country}
          />
        </Styled.DropDownWrapper>
        <Styled.DropDownWrapper>
          <Label align="left">{t(TranslationKey.page1_select_product)}</Label>
          <DropDownField
            fullWidth
            disabled={!country}
            disabledText={t(TranslationKey.page1_select_country)}
            control={control}
            errors={errors}
            name="product"
            placeholder={t(TranslationKey.page1_product)}
            options={productOptions}
          />
        </Styled.DropDownWrapper>
        <ProductViewer productImages={productImages} product={product} />
      </Styled.FormElementWrapper>
      <Styled.Element>
        <ActionButton
          type="submit"
          variant={ActionButtonVariant.primary}
          size={ActionButtonSize.wide}
          title="Next"
          loading={isSubmitting}
        >
          {t(TranslationKey.next_button)}
        </ActionButton>
        <Styled.TermsText>
          {t(TranslationKey.page1_terms_desktop_1)}{' '}
          <Styled.TermsLink to={'/terms-of-condition'}>
            {t(TranslationKey.terms_of_condition)}
          </Styled.TermsLink>
        </Styled.TermsText>
      </Styled.Element>
    </Styled.Wrapper>
  );
};
