import * as yup from 'yup';
import { ConfigurationPageFormValues } from './education-features-configuration-page.types';
import { TranslationKey } from '@nn-virtual-pen/education/data-access';

export const schemaFactory = (
  includeCountry,
  includeProduct,
  includeTermsAndConditions,
  t,
) =>
  yup.object().shape<ConfigurationPageFormValues>({
    ...(includeCountry && {
      country: yup.string().required(t(TranslationKey.validation_required)),
    }),
    ...(includeProduct && {
      product: yup.string().required(t(TranslationKey.validation_required)),
    }),
    ...(includeTermsAndConditions && {
      termsAndConditions: yup
        .boolean()
        .oneOf([true], t(TranslationKey.validation_terms))
        .required(t(TranslationKey.validation_required)),
    }),
  });
