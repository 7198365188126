import styled from 'styled-components';
import layout1 from './layout1.svg';
import layout2 from './layout2.svg';
import { Title as BaseTitle } from '@nn-virtual-pen/education/ui';
import { MobileLayoutVariant } from './mobile-layout.types';

const layouts = {
  [MobileLayoutVariant.layout1]: {
    bg: layout1,
    gradient: 'linear-gradient(90deg, #75489f, #f0027f)',
    pos: 'bottom',
  },
  [MobileLayoutVariant.layout2]: {
    bg: layout2,
    gradient: 'linear-gradient(90deg, #c471ed, #f7797d)',
    pos: 'initial',
  },
  [MobileLayoutVariant.layout3]: {
    bg: layout2,
    gradient: 'linear-gradient(90deg, #c471ed, #f7797d)',
    pos: 'right'
  },
};

export const Wrapper = styled.form<{ variant: MobileLayoutVariant }>`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ variant, theme }) =>
    variant === MobileLayoutVariant.layout1
      ? theme.palette.introBg
      : theme.palette.white};
  min-height: 100%;
`;

export const Header = styled.div<{ variant: MobileLayoutVariant }>`
  height: 336px;
  width: 100%;
  padding: 28px 21px 0;

  position: relative;
  background: ${props => layouts[props.variant].gradient};

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: url(${props => layouts[props.variant].bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${props => layouts[props.variant].pos};
  }
`;

export const Content = styled.div`
  padding: 30px 28px 0;
`;

export const Footer = styled.div`
  padding: 0 37px 21px;
  text-align: center;
`;

export const Title = styled(BaseTitle)`
  font-size: 27px;
  line-height: 40px;
  margin: 10px 0;
  text-align: left;
`;

export const Text = styled.p<{ primary?: boolean }>`
  font-size: 13px;
  line-height: 18px;
  color: ${(props) =>
    props.primary ? props.theme.palette.nnBlue : props.theme.palette.white};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;

export const FormElement = styled.div`
  margin-bottom: 48px;
`;
