import styled from 'styled-components';
import { Link } from '@reach/router';

export const TermsLink = styled(Link)`
  text-decoration: underline;
`;

export const ToggleWrapper = styled.div`
  text-align: center;
`;
