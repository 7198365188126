import { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';

export enum MobileLayoutVariant {
  layout1 = 'layout1',
  layout2 = 'layout2',
  layout3 = 'layout3',
}
export interface MobileLayoutProps {
  handleSubmit: () => void;
  formState: UseFormMethods['formState'];
  header: ReactElement;
  variant: MobileLayoutVariant;
  buttonText: string;
  buttonTitle: string;
}
