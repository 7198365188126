import { useForm } from 'react-hook-form';
import { useMemo, useRef } from 'react';
import { useYupValidationResolver } from '@nn-virtual-pen/utils';
import { schemaFactory } from './education-features-configuration-page.schema';
import { ConfigurationPageFormValues } from './education-features-configuration-page.types';
import { ObjectSchema } from 'yup';
import { useTranslate } from '@nn-virtual-pen/education/data-access';

export const useProductOptions = (country, fieldOptions) => {
  return useMemo(() => {
    if (!country) {
      return [];
    }

    return fieldOptions.product.get(country);
  }, [country, fieldOptions]);
};

export const useConfiguration = (
  { includeCountry, includeProduct, includeTermsAndConditions },
  { country: defaultCountry, product: defaultProduct },
  callback
) => {
  const { t } = useTranslate();

  const { current: validationSchema } = useRef<
    ObjectSchema<ConfigurationPageFormValues>
  >(schemaFactory(includeCountry, includeProduct, includeTermsAndConditions, t));
  const resolver = useYupValidationResolver(validationSchema);

  const { handleSubmit, formState, control, reset, watch, setValue } = useForm<
    ConfigurationPageFormValues
  >({
    resolver,
    defaultValues: {
      country: defaultCountry,
      product: defaultProduct,
      termsAndConditions: false,
    },
  });

  // todo: temporary solution
  const onSubmit = handleSubmit(async (values) => {
    try {
      await callback(values);

      // do something here e.g. navigate to next page
    } catch (error) {
      // handle error
    }
  });

  return {
    setValue,
    reset,
    watch,
    control,
    formState,
    onSubmit,
  };
};
