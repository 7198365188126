import React from 'react';
import { Label } from '@nn-virtual-pen/education/ui';
import { DropDownField } from '@nn-virtual-pen/ui';
import {
  useConfiguration,
  useProductOptions,
} from '../education-features-configuration-page.hook';
import {
  FormElement,
  MobileLayout,
  MobileLayoutVariant,
  Text,
  Title,
} from '../mobile-layout';
import { ProductViewer } from '../product-viewer';
import {
  TranslationKey,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';
import * as Styled from './mobile-product-selection.styled';

export const MobileProductSelection = ({
  configuration,
  handleGoBack,
  callback,
  fieldOptions,
  country,
  productImages,
}) => {
  const { t } = useTranslate();

  const { watch, formState, control, onSubmit } = useConfiguration(
    {
      includeCountry: false,
      includeProduct: true,
      includeTermsAndConditions: false,
    },
    configuration,
    callback
  );

  const productOptions = useProductOptions(country, fieldOptions);

  const { errors } = formState;
  const product = watch('product');

  return (
    <MobileLayout
      buttonText={t(TranslationKey.next_button)}
      buttonTitle={t(TranslationKey.next_button)}
      variant={MobileLayoutVariant.layout2}
      formState={formState}
      handleSubmit={onSubmit}
      header={
        <>
          <Styled.GoBackButton onClick={handleGoBack}>
            <Styled.Content>
              <Styled.LeftArrowIcon />
              {t(TranslationKey.goback_button)}
            </Styled.Content>
          </Styled.GoBackButton>
          <Title>{t(TranslationKey.page2_select)}</Title>
          <Text>{t(TranslationKey.page2_select_2)}</Text>
        </>
      }
    >
      <FormElement>
        <Label align="left">{t(TranslationKey.select_button)}</Label>
        <DropDownField
          fullWidth
          control={control}
          errors={errors}
          name="product"
          placeholder={t(TranslationKey.page1_product)}
          options={productOptions}
        />
      </FormElement>
      <FormElement>
        <ProductViewer productImages={productImages} product={product} />
      </FormElement>
    </MobileLayout>
  );
};
