import React, { useState } from 'react';
import { MobileCountrySelection } from '../mobile-country-selection';
import { MobileProductSelection } from '../mobile-product-selection';

enum MobileConfigurationStep {
  country = 'country',
  product = 'product',
}

export const MobileConfiguration = (props) => {
  const [step, setStep] = useState<MobileConfigurationStep>(
    MobileConfigurationStep.country
  );

  const { configuration, callback } = props;

  const handleNextStep = async (values) => {
    await callback(values);
    setStep(MobileConfigurationStep.product);
  };

  const handleGoBack = () => {
    setStep(MobileConfigurationStep.country);
  };

  if (step === MobileConfigurationStep.country) {
    return <MobileCountrySelection {...props} callback={handleNextStep} />;
  }

  return (
    <MobileProductSelection
      {...props}
      handleGoBack={handleGoBack}
      country={configuration.country}
    />
  );
};
