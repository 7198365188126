import React from 'react';
import * as Styled from './product-disclaimer.styled';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';

export const ProductDisclaimer = () => {
  const { t } = useTranslate();

  return (
    <Styled.Wrapper>
      <Styled.IconWrapper>
        <Styled.WarningIcon />
      </Styled.IconWrapper>
      <div>
        <Styled.Header>{t(TranslationKey.product_disclaimer_header)}</Styled.Header>
        <Styled.Text>
          {t(TranslationKey.product_disclaimer_text)}
        </Styled.Text>
      </div>
    </Styled.Wrapper>
  )
};
