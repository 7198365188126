import React, { FC } from 'react';
import * as Styled from './mobile-layout.styled';
import {
  BlurredCircle as StyledBlurredCircle,
  BlurredCirclePosition,
} from '@nn-virtual-pen/education/ui';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import { MobileLayoutProps, MobileLayoutVariant } from './mobile-layout.types';

export const MobileLayout: FC<MobileLayoutProps> = ({
  handleSubmit,
  formState,
  header,
  buttonText,
  buttonTitle,
  variant,
  children,
}) => {
  const { isSubmitting } = formState;

  return (
    <Styled.Wrapper variant={variant} onSubmit={handleSubmit}>
      {variant === MobileLayoutVariant.layout1 && (
        <StyledBlurredCircle position={BlurredCirclePosition.left} />
      )}
      <div>
        <Styled.Header variant={variant}>{header}</Styled.Header>
        <Styled.Content>{children}</Styled.Content>
      </div>
      <Styled.Footer>
        <ActionButton
          type="submit"
          variant={ActionButtonVariant.primary}
          size={ActionButtonSize.wide}
          title={buttonTitle}
          loading={isSubmitting}
        >
          {buttonText}
        </ActionButton>
      </Styled.Footer>
    </Styled.Wrapper>
  );
};
