import styled from 'styled-components';
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt';
import { path } from 'ramda';

export const GoBackButton = styled.button.attrs({ type: 'button' })`
  appearance: none;
  background-color: rgba(0, 0, 0, .1);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 4px 14px 4px 7px;
  border-radius: 20px;
  color: ${path(['theme', 'palette', 'nnBlue'])};
  transition: background-color 0.2s, box-shadow 0.4s;

  &:hover:enabled {
    background-color: rgba(0, 0, 0, .25);
  }
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
`;

export const LeftArrowIcon = styled(LeftArrowAlt)`
  width: 20px;
`;
