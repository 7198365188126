
import React from 'react';
import * as Styled from './product-viewer.styled';
import { ProductDisclaimer } from '../product-disclaimer';

export const ProductViewer = ({ product, productImages }) => {
  if (!product) {
    return null;
  }

  return (
    <div>
      <Styled.ImgWrapper>
        <Styled.Img srcSet={productImages.get(product)} alt={product} />
      </Styled.ImgWrapper>
      <ProductDisclaimer />
    </div>
  );
};
