import React from 'react';
import * as Styled from './mobile-country-selection.styled';
import { Label } from '@nn-virtual-pen/education/ui';
import { DropDownField, ToggleField } from '@nn-virtual-pen/ui';
import { useConfiguration } from '../education-features-configuration-page.hook';
import {
  FormElement,
  MobileLayout,
  MobileLayoutVariant,
  Text,
  Title,
} from '../mobile-layout';
import {
  TranslationKey,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';

export const MobileCountrySelection = ({
  configuration,
  callback,
  fieldOptions,
}) => {
  const { t } = useTranslate();

  const { formState, control, onSubmit } = useConfiguration(
    {
      includeCountry: true,
      includeProduct: false,
      includeTermsAndConditions: true,
    },
    configuration,
    callback
  );

  const { errors } = formState;

  return (
    <MobileLayout
      buttonText={t(TranslationKey.next_button)}
      buttonTitle={t(TranslationKey.next_button)}
      variant={MobileLayoutVariant.layout1}
      formState={formState}
      handleSubmit={onSubmit}
      header={
        <>
          <Title>{t(TranslationKey.page1_intro_text)}</Title>
          <Text>{t(TranslationKey.page1_header)}</Text>
          <Text primary>{t(TranslationKey.page1_header_2)}</Text>
        </>
      }
    >
      <FormElement>
        <Label align="left">{t(TranslationKey.page1_select_country)}</Label>
        <DropDownField
          fullWidth
          control={control}
          errors={errors}
          name="country"
          placeholder={t(TranslationKey.page1_country)}
          options={fieldOptions.country}
        />
      </FormElement>
      <FormElement>
        <Label align="left">
          {t(TranslationKey.page1_terms)}{' '}
          <Styled.TermsLink to={'/terms-of-condition'}>
            {t(TranslationKey.terms_of_condition)}
          </Styled.TermsLink>
        </Label>
        <Styled.ToggleWrapper>
          <ToggleField
            name="termsAndConditions"
            control={control}
            errors={errors}
          />
        </Styled.ToggleWrapper>
      </FormElement>
    </MobileLayout>
  );
};
